import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import VueRouter from 'vue-router';
import routes from './routers/index'

Vue.use(VueRouter);
Vue.use(ElementUI);

Vue.config.productionTip = false

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        // 页面切换回到顶部
        return {
            y: 0,
        };
    },
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
