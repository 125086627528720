import Layout from "@/components/Layout";
import ErrorPage from '@/components/404.vue'
import HomeIndex from '@/components/Home';
import ShopIndex from '@/components/Shop';
import PriceIndex from '@/components/Price';
import BusinessIndex from '@/components/Business';
import AboutIndex from '@/components/About'


const routes = [
    // 默认页面
    {path:'/', redirect:'/home'},
    {
        path: '/',
        component: Layout,
        children: [
            {path: '/home', name: 'HomeIndex', component: HomeIndex},
            {path: '/shop', name: 'ShopIndex', component: ShopIndex},
            {path: '/price', name: 'PriceIndex', component: PriceIndex},
            {path: '/business', name: 'BusinessIndex', component: BusinessIndex},
            {path: '/about', name: 'AboutIndex', component: AboutIndex}
        ]
    },
    // 拦截所有无效的页面到错误页面
    {path: '*', component: ErrorPage, name: 'ErrorPage'}
]

export default routes
