<template>
    <div class="about-container">
        <!-- 我们提供专业的客户服务 -->
        <div class="about-us">
            <div class="about-us-box">
                <div class="about-us-box-top">
                    <div class="top-left">
                        <div class="small-title">关于我们</div>
                        <div class="title">我们是做什么的？</div>
                        <div class="intro">
                            为企业级客户提供全流程私域流量运营工具和咨询服务，构建以平台为基础，涵盖SCRM，群运营等产品；适用于微信群运营，客户资源管理，销售行为监管和微信生态数据打通等使用场景；赋能教育培训，金融保险，医疗，企业服务，家居，汽车，电商零售等行业，帮助企业客户持续增长
                        </div>
                    </div>
                    <div class="top-right">
                        <img src="@/assets/images/about/bg-1.png" alt="">
                    </div>
                </div>
                <div class="about-us-box-middle">
                    <div class="middle-top">
                        <div class="top-left">
                            <div class="small-title">我们的使命和愿景</div>
                            <div class="title">成为企业私域建设的首选工具</div>
                            <div class="intro">
                                每个企业都需要一款私域流量营销管理工具，我们的愿景是实现全国3000+座城市落地，为总计1亿+以上实体店铺私域建设贡献力量；
                            </div>
                        </div>
                        <div class="top-right">
                            <div class="small-title">我们的价值观</div>
                            <div class="title">成就他人，造就自己</div>
                            <div class="intro">
                                帮助他人成功，才是我们自己最大的成功；做企业值得信赖的成长伙伴；共同进入数字化赋能时代；
                            </div>
                        </div>
                    </div>
                    <div class="middle-bottom"></div>
                </div>
                <div class="about-us-box-bottom">
                    <div class="title">我们的发展历程</div>
                    <div class="content">
                        <div class="content-item">
                            <div class="item-top">
                                <div class="top-round"></div>
                                <div class="top-line"></div>
                            </div>
                            <div class="item-year">2018年</div>
                            <div class="item-intro">
                                公司注册成立，妙狐SAAS小程序正式发布上线，接入客户数量突破500家
                            </div>
                        </div>
                        <div class="content-item">
                            <div class="item-top">
                                <div class="top-round"></div>
                                <div class="top-line"></div>
                            </div>
                            <div class="item-year">2019年</div>
                            <div class="item-intro">
                                探索全新的商业模式，丰富和完善小程序功能，将产品运用到各个领域，在业内积累良好的口碑
                            </div>
                        </div>
                        <div class="content-item">
                            <div class="item-top">
                                <div class="top-round"></div>
                                <div class="top-line"></div>
                            </div>
                            <div class="item-year">2020年</div>
                            <div class="item-intro">
                                妙狐SCRM系统隆重上线，赋能商家通过数字化营销管理工具构建自有的私域流量池
                            </div>
                        </div>
                        <div class="content-item">
                            <div class="item-top">
                                <div class="top-round"></div>
                                <div class="top-line"></div>
                            </div>
                            <div class="item-year">2021年</div>
                            <div class="item-intro">
                                全网数据打通，妙狐商城深度整合，为商家提供从引流到转化，从转化留存到裂变的全流程管理平台
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 我们提供专业的客户服务 -->
        <div class="professional-customer-service">
            <div class="professional-customer-service-box">
                <div class="title">我们提供专业的客户服务</div>
                <div class="intro">服务数万家客户拥有丰富的服务经验，提供专业解决方案及全方位服务，助力客户成功</div>
                <div class="content">
                    <div class="content-item">
                        <img src="@/assets/images/about/icon-1.png" alt="">
                        <div class="item-title">专属服务管家1V1</div>
                        <div class="item-intro">我们为每个客户提供一对一的专属客服，协助快速解决使用过程中的问题</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/about/icon-2.png" alt="">
                        <div class="item-title">急速响应，高效反馈</div>
                        <div class="item-intro">在线留言5分钟内极速响应，需求24小时内高效反馈第一时间服务客户</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/about/icon-3.png" alt="">
                        <div class="item-title">保障365日全天候护航</div>
                        <div class="item-intro">365日全天候服务护航，保障客户业务顺利开展</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 我们提供可信赖的合作 -->
        <div class="reliable-cooperation">
            <div class="reliable-cooperation-box">
                <div class="title">我们提供可信赖的合作</div>
                <div class="intro">服务数万家客户拥有丰富的服务经验，提供专业解决方案及全方位服务，助力客户成功</div>
                <div class="content">
                    <div class="content-item">
                        <div class="item-num">1000+</div>
                        <div class="item-tip">企业/团队信任之选</div>
                    </div>
                    <div class="content-item">
                        <div class="item-num">250000+</div>
                        <div class="item-tip">企微生态小C客户池</div>
                    </div>
                    <div class="content-item">
                        <div class="item-num">100+</div>
                        <div class="item-tip">产品技术团队</div>
                    </div>
                    <div class="content-item">
                        <div class="item-num">50+</div>
                        <div class="item-tip">运营售后服务团队</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutIndex"
}
</script>

<style scoped lang="less">
.about-container {
    .about-us {
        height: 1824px;
        background: rgba(255, 255, 255, 1);

        .about-us-box {
            width: 1280px;
            margin: 0 auto;

            .about-us-box-top {
                height: 336px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 120px;

                .top-left {
                    .small-title {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 32px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                    }

                    .title {
                        font-size: 54px;
                        font-weight: 700;
                        line-height: 74px;
                        color: rgba(40, 41, 56, 1);
                        margin-top: 8px;
                        margin-bottom: 30px;
                    }

                    .intro {
                        width: 484px;
                        height: 140px;
                        opacity: 0.7;
                        /** 文本1 */
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                    }
                }

                .top-right {
                    img {
                        width: 547px;
                        height: 336px;
                    }
                }
            }

            .about-us-box-middle {
                height: 716px;
                opacity: 1;
                margin-top: 128px;

                .middle-top {
                    box-sizing: border-box;
                    height: 376px;
                    opacity: 1;
                    background: #F4F5F5;
                    padding: 84px 80px;
                    display: flex;
                    justify-content: space-around;

                    .small-title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 28px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                    }

                    .title {
                        width: 515px;
                        height: 56px;
                        /** 文本1 */
                        font-size: 38px;
                        font-weight: 600;
                        line-height: 56px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                        margin: 20px 0 16px 0;
                    }

                    .intro {
                        width: 515px;
                        height: 56px;
                        opacity: 0.7;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                    }
                }

                .middle-bottom {
                    height: 340px;
                    background: url('@/assets/images/shop/bg-7.jpg') 50%;
                    -webkit-background-size: 100%;
                    background-size: 100%;
                }
            }

            .about-us-box-bottom {
                height: 302px;
                margin-top: 122px;

                .title {
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 64px;
                    color: rgba(40, 41, 56, 1);
                    text-align: center;
                }

                .content {
                    height: 185px;
                    margin-top: 53px;
                    display: flex;
                    justify-content: space-around;

                    .content-item {
                        .item-top {
                            width: 272px;
                            height: 24px;
                            display: flex;
                            align-items: center;

                            .top-round {
                                width: 24px;
                                height: 24px;
                                background: rgba(36, 5, 242, 1);
                                border-radius: 100px;
                            }

                            .top-line {
                                width: 224px;
                                height: 2px;
                                background-image: linear-gradient(to right, rgba(167, 155, 250) 0%, rgba(167, 155, 250) 50%, transparent 0%);
                                background-size: 10px 2px;
                                background-repeat: repeat-x;
                                margin-left: 24px;
                            }
                        }

                        .item-year {
                            /** 文本1 */
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 36px;
                            color: rgba(40, 41, 56, 1);
                            text-align: left;
                            margin-top: 32px;
                            margin-bottom: 8px;
                        }

                        .item-intro {
                            width: 272px;
                            height: 56px;
                            opacity: 0.7;
                            /** 文本1 */
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 28px;
                            color: rgba(40, 41, 56, 1);
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .professional-customer-service {
        height: 694px;
        background: rgba(244, 246, 252, 1);

        .professional-customer-service-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                padding-top: 98px;
                text-align: center;
            }

            .intro {
                width: 614px;
                height: 28px;
                opacity: 0.7;
                /** 文本1 */
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin: 17px auto 69px;
            }

            .content {
                display: flex;
                justify-content: space-evenly;

                .content-item {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: space-between;
                    width: 362px;
                    height: 279.96px;
                    opacity: 1;
                    background: rgba(255, 255, 255, 1);
                    padding: 31px 11px 18px 11px;
                    box-sizing: border-box;

                    img {
                        width: 109px;
                        height: 109px;
                    }


                    .item-title {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 36px;
                        color: rgba(29, 33, 48, 1);
                        text-align: center;
                    }

                    .item-intro {
                        width: 338px;
                        height: 60px;
                        opacity: 0.7;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 28px;
                        color: rgba(29, 33, 48, 1);
                        text-align: center;
                    }
                }
            }
        }

    }

    .reliable-cooperation {
        height: 514px;
        background: rgba(255, 255, 255, 1);

        .reliable-cooperation-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                padding-top: 98px;
                text-align: center;
            }

            .intro {
                width: 614px;
                height: 28px;
                opacity: 0.7;
                /** 文本1 */
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin: 17px auto 69px;
            }

            .content {
                display: flex;
                justify-content: space-evenly;

                .content-item {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;

                    .item-num {
                        font-size: 48px;
                        font-weight: 600;
                        line-height: 64px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                    }

                    .item-tip {
                        opacity: 0.7;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 28px;
                        color: rgba(40, 41, 56, 1);
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>