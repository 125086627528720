<template>
    <div class="layout-container">
        <Header></Header>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Layout',
    components: {
        Header,
        Footer,
    }
}
</script>

<style scoped>
.layout-container {
    position: relative;
    height: 100vh;
    padding-top: 100px;
}
</style>
