<template>
    <div class="business-container">
        <div class="business">
            <div class="business-box">
                <div class="title">商务合作</div>
                <div class="intro">加入我们成为妙狐市合作伙伴千亿蓝海市场，等你我共同开拓</div>
                <div class="content">
                    <div class="content-item">
                        <div class="content-item-box">
                            <div class="label-name">*姓名</div>
                            <div class="label-input">
                                <input type="text" v-model="form.name" placeholder="请输入您的姓名">
                            </div>
                        </div>
                        <div class="content-item-box">
                            <div class="label-name">*电话</div>
                            <div class="label-input">
                                <input type="text" v-model="form.phone" placeholder="请输入您的手机号码">
                            </div>
                        </div>
                    </div>
                    <div class="content-item">
                        <div class="content-item-box">
                            <div class="label-name">所在城市</div>
                            <div class="label-input">
                                <input type="text" v-model="form.city" placeholder="请输入您所在的城市">
                            </div>
                        </div>
                        <div class="content-item-box">
                            <div class="label-name">邮箱</div>
                            <div class="label-input">
                                <input type="text" v-model="form.email" placeholder="请输入您的电子邮箱">
                            </div>
                        </div>
                    </div>
                    <div class="content-item">
                        <div class="content-item-box">
                            <div class="label-name">备注</div>
                            <div class="label-input">
                                <textarea v-model="form.remark" id="remark" placeholder="备注信息"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="content-item">
                        <div class="btn-submit" @click="freeTrial">提交申请</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 客服弹框 -->
        <KfPopUp :isShow="isShowKf" @closeKf="closeKf"></KfPopUp>
    </div>
</template>

<script>
import KfPopUp from "@/components/KfPopUp";

export default {
    name: "BusinessIndex",
    components: {
        KfPopUp
    },
    data() {
        return {
            form: {
                name: '',
                phone: '',
                city: '',
                email: '',
                remark: '',
            },
            isShowKf: false
        }
    },
    methods: {
        // 免费试用
        freeTrial() {
            this.isShowKf = true;
        },
        // 关闭客服弹窗
        closeKf() {
            this.isShowKf = false;
        },
    }
}
</script>

<style scoped lang="less">
.business-container {
    .business {
        height: 1088px;
        background: rgba(255, 255, 255, 1);

        .business-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                padding-top: 120px;
            }

            .intro {
                opacity: 0.7;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin-top: 16px;
                margin-bottom: 92px;
            }

            .content {
                width: 1061px;
                height: 640px;
                opacity: 1;
                border-radius: 12px;
                background: rgba(244, 246, 252, 1);
                box-sizing: border-box;
                margin: 0 auto;
                padding: 55px 77px;

                .content-item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 24px;

                    .content-item-box {
                        width: 437px;

                        .label-name {
                            font-size: 18px;
                            font-weight: 600;
                            color: rgba(0, 0, 0, 1);
                            text-align: left;
                        }

                        .label-input {
                            margin-top: 6px;

                            input {
                                -webkit-appearance: none;
                                outline: none;
                                box-sizing: border-box;
                                width: 438px;
                                height: 64px;
                                opacity: 1;
                                border-radius: 8px;
                                border: 1px solid rgba(0, 0, 0, 0.12);
                                background: rgba(244, 246, 252, 1);
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                padding: 0 32px;


                            }

                            textarea {
                                outline: none;
                                resize: none;
                                width: 908px;
                                height: 138px;
                                opacity: 1;
                                border-radius: 8px;
                                border: 1px solid rgba(0, 0, 0, 0.12);
                                background: rgba(244, 246, 252, 1);
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: rgba(0, 0, 0, 1);
                                padding: 18px 32px;
                                box-sizing: border-box;
                            }
                        }
                    }

                    .btn-submit {
                        width: 254px;
                        height: 64px;
                        border-radius: 41px;
                        background: rgba(252, 217, 128, 1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 32px;
                        color: rgba(40, 41, 56, 1);
                        margin-top: 8px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>