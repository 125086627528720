<template>
    <div class="kf-modal" v-if="isShow">
        <div class="kf-bg">
            <div class="kf-title">添加您的专属客服</div>
            <div class="kf-tip">获取一对一咨询，免费开通体验账户</div>
            <img class="kf-img" src="@/assets/images/qrcode-kf.png" alt="">
            <div class="kf-phone">联系电话：13924613600</div>
            <div class="kf-close">
                <img src="@/assets/images/kf-close.png" alt="" @click="close">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            default: false,
        }
    },
    name: "KfPopUp",
    data() {
        return {

        }
    },
    methods: {
        close() {
            this.$emit("closeKf");
        }
    }
}
</script>

<style scoped lang="less">
.kf-modal {
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 403px;
    height: 579.49px;
    opacity: 1;
    text-align: center;
    z-index: 10;

    .kf-bg {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 0 20px 4px rgba(218, 213, 237, 1);
        border-radius: 8px;
        height: 503px;

        .kf-title {
            font-size: 24px;
            font-weight: 700;
            color: rgba(0, 0, 0, 1);
            text-align: center;
            padding-top: 44px;
        }

        .kf-tip {
            font-size: 20px;
            font-weight: 400;
            color: rgba(166, 166, 166, 1);
            text-align: center;
            margin-top: 16px;
        }

        .kf-img {
            width: 215px;
            height: 215px;
            box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.16);
            margin-top: 31px;
        }

        .kf-phone {
            font-size: 20px;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
            text-align: center;
            margin-top: 33px;
        }

        .kf-close {
            img {
                width: 52px;
                height: 52px;
                position: absolute;
                bottom: 0;
                left: 201.5px;
                margin-left: -26px;
                cursor: pointer;
            }
        }
    }
}
</style>