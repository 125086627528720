<template>
    <div class="header-container">
        <img class="banner-l" src="@/assets/images/home/banner-bg-l.png" alt="">
        <div class="header">
            <div class="logo-box">
                <router-link to="/home">
                    <img class="logo" src="@/assets/images/logo.png" alt=""/>
                    <img class="title" src="@/assets/images/title.png" alt="">
                </router-link>
            </div>
            <div class="menu-box">
                <div class="menu" v-for="(item, index) in menuList" :key="index">
                    <router-link @click.native="menuClick(item, index)" :to="item.path"
                                 :class="activeIndex === index? 'active' : '' ">
                        {{ item.name }}
                    </router-link>
                </div>
                <div class="menu">
                    <a href="https://pc.miaohucloud.com/register" target="_blank">登陆</a>
                </div>
                <!--<div class="menu">-->
                <!--    <a href="http://nt5hsdzq.test-scrm.miaohucloud.com/register" target="_blank">立即试用</a>-->
                <!--</div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Header',
    data() {
        return {
            activeIndex: 0,
            menuList: [{
                'name': '首页',
                'path': '/home'
            }, {
                'name': '妙狐商城',
                'path': '/shop'
            }, {
                'name': '产品定价',
                'path': '/price'
            }, {
                'name': '商务合作',
                'path': '/business'
            }, {
                'name': '关于我们',
                'path': '/about'
            }]
        };
    },
    methods: {
        menuClick(item, index) {
            if (item.path === this.$route.path) {
                this.activeIndex = index;
            }
        }
    },
    created() {
        this.activeIndex = this.menuList.findIndex(item => {
            return item.path === this.$route.path;
        })
    }
}
</script>

<style scoped lang="less">
.header-container {
    height: 100px;
    background: #6246e4;
    opacity: 1;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    overflow: hidden;

    .banner-l {
        width: 118px;
        height: 661px;
        position: absolute;
        top: -12px;
        left: 0;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1280px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;


        .logo-box {
            display: inline-flex;
            align-items: center;
            height: 100px;
            cursor: pointer;

            .logo {
                height: 52px;
                width: 52px;
            }

            .title {
                width: 104px;
                height: 37px;
                margin-left: 7px;
                margin-top: 10px;
                cursor: pointer;
            }
        }

        .menu-box {
            display: inline-flex;
            align-items: center;

            .menu {
                height: 33px;
                opacity: 1;
                /** 文本1 */
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 28px;
                color: rgba(187, 187, 203, 1);
                text-align: center;
                vertical-align: top;
                margin-left: 29px;
                cursor: pointer;

                a {
                    text-decoration: none;
                    color: rgba(187, 187, 203, 1);
                }

                .active {
                    color: rgba(255, 255, 255, 1);
                }
            }


            /*.menu:last-child {
                width: 185px;
                height: 65.22px;
                opacity: 1;
                border-radius: 41px;
                border: 2px solid rgba(244, 246, 252, 0.2);
                display: flex;
                justify-content: center;
                align-items: center;
                left: 1314px;
                top: 39.13px;
                !** 文本1 *!
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 28px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
                vertical-align: top;
            }*/
        }
    }

}
</style>
