<template>
    <div class="shop-container">
        <!-- 妙狐分销商城 -->
        <div class="wind-control">
            <div class="wind-control-box">
                <div class="wind-left">
                    <div class="title">妙狐分销商城</div>
                    <div class="m-title">快速拥有适合自己的分销商城，获取流量销量双增长</div>
                    <div class="intro">
                        <div class="intro-item">
                          <img src="@/assets/images/shop/icon-code.png" alt="">
                          <div>无需开发，0代码上线</div>
                        </div>
                        <div class="intro-item">
                          <img src="@/assets/images/shop/icon-android.png" alt="">
                          <img src="@/assets/images/shop/icon-apple.png" alt="">
                          <img src="@/assets/images/shop/icon-h5.png" alt="">
                          <div>安卓、苹果、H5、小程序商城一次发布</div>
                        </div>
                        <div class="intro-item">
                          <img src="@/assets/images/shop/icon-market.png" alt="">
                          <div>秒杀、团购、分销各种营销玩法随你选用</div>
                        </div>
                    </div>
                    <div class="button">
                        <div class="btn-name" @click="freeTrial">免费试用</div>
                    </div>
                </div>
                <div class="wind-right">
                    <div>
                        <img src="@/assets/images/shop/bg-1.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 妙狐SAAS商城系统，你想要的功能都有 -->
        <div class="miaohu-sass">
            <div class="miaohu-sass-box">
                <div class="title">妙狐SAAS商城系统，你想要的功能都有</div>
                <div class="intro">
                    完美契合私域流量变现闭环交易使用。 系统拥有丰富的营销玩法，强大的分销能力，支持DIY多模板，前后端分离；微信小程序/H5/头条小程序/安卓IOS
                    APP 多端销售
                </div>
                <div class="content">
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-1.png" alt="">
                        <div class="content-title">可视化DIY</div>
                        <div class="content-intro">全商城界面可视化拖拉拽DIY，多模板，多风格机制，极大便利运营装修。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-2.png" alt="">
                        <div class="content-title">限时秒杀</div>
                        <div class="content-intro">限时秒杀，解决库存，引流利器。电商运营不可或缺的直接手段。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-3.png" alt="">
                        <div class="content-title">拼团活动</div>
                        <div class="content-intro">N人成团有优惠，快速提升商城订单量。你拉我，我拉你，再造一个拼多多。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-4.png" alt="">
                        <div class="content-title">砍价活动</div>
                        <div class="content-intro">
                            社交电商火热引流的方式之一，通过好友砍价带来大量新粉丝，同时提高商城订单量。
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-5.png" alt="">
                        <div class="content-title">分销裂变</div>
                        <div class="content-intro">微信社交分销裂变，一键分享，快速发展商城下线粉丝，粉丝下单，上级躺赚。
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-6.png" alt="">
                        <div class="content-title">充值有礼</div>
                        <div class="content-intro">充的多送的多，沉淀客户资金，长期锁定忠实用户提升客单价。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-7.png" alt="">
                        <div class="content-title">电子面单</div>
                        <div class="content-intro">
                            接入快递100等第三方硬件设备，实现批量电子面单打印，无需手动填写发货单。
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-8.png" alt="">
                        <div class="content-title">优惠券</div>
                        <div class="content-intro">
                            优惠券是活动促销的不二之选，大额券、隐藏券、指定商品券，精准带动商品销量。
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-9.png" alt="">
                        <div class="content-title">积分签到</div>
                        <div class="content-intro">签到得积分，不断培养用户粘性，占领用户屏幕时长和购物心智。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-10.png" alt="">
                        <div class="content-title">小程序直播</div>
                        <div class="content-intro">
                            电商直播是未来主流的卖货形式，创建直播间，管理直播商品，随时随地开启购物直播。
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-11.png" alt="">
                        <div class="content-title">随心支付</div>
                        <div class="content-intro">余额支付、微信支付、支付宝支付，适用微信公众号、H5、APP等多渠道场景。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/icon-12.png" alt="">
                        <div class="content-title">零钱提现</div>
                        <div class="content-intro">
                            支持多种提现方式，余额，微信零钱，银行卡，微信收款码，支付宝收款码。零钱提现秒到账。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 商城私有化部署 -->
        <div class="wind-control">
            <div class="wind-control-box">
                <div class="wind-left">
                    <div class="title">商城私有化部署</div>
                    <div class="m-title">支持私有化部署，打造专属的分销团队</div>
                    <div class="intro">
                        <div class="intro-item">部署方式灵活：支持企业本地、 内网部署，支持第三方云服务平台</div>
                        <div class="intro-item">高可用性能架构：支持已有项目迁移至私有化版本，支持存储资源、席位动态扩容</div>
                    </div>
                    <div class="button">
                        <div class="btn-name" @click="freeTrial">免费试用</div>
                    </div>
                </div>
                <div class="wind-right">
                    <div>
                        <img src="@/assets/images/shop/bg-2.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 从上线到运营，全流程持续为您赋能 -->
        <div class="process">
            <div class="process-box">
                <div class="title">从上线到运营，全流程持续为您赋能</div>
                <div class="intro">
                    零售、金融、电商、餐饮、美容、教育多行业成功案例实践分享
                </div>
                <div class="content">
                    <div class="content-item">
                        <img src="@/assets/images/shop/bg-3.png" alt="">
                        <div class="content-title">经营策略</div>
                        <div class="content-intro">为您分析行业市场及目标人群，深挖需求痛点，定制专属解决方案。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/bg-4.png" alt="">
                        <div class="content-title">服务团队</div>
                        <div class="content-intro">
                            专业运营团队从实施上线到运营落地陪跑式服务，随时监督和指导，贡献运营建议。
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/bg-5.png" alt="">
                        <div class="content-title">广告资源</div>
                        <div class="content-intro">海量优质广告资源位提供，营销活动精准触达目标客户群。</div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/shop/bg-6.png" alt="">
                        <div class="content-title">定制开发</div>
                        <div class="content-intro">5年技术沉淀，提供个性化高品质定制服务，安全持续保障。</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 妙狐商城系统，建立您的专属分销团队 -->
        <div class="large-banner">
            <div class="mask"></div>
            <div class="title">妙狐商城系统，建立您的专属分销团队</div>
            <div class="content">
                无需开发投入少周期短、速度快、推广易；多端商城，一次性拥有超多流量入口；良好的购物体验；各种营销插件助力您的销售
            </div>
        </div>

        <!-- 客服弹框 -->
        <KfPopUp :isShow="isShowKf" @closeKf="closeKf"></KfPopUp>
    </div>
</template>

<script>
import KfPopUp from "@/components/KfPopUp";

export default {
    name: "ShopIndex",
    components: {
        KfPopUp
    },
    data() {
        return {
            isShowKf: false,
        };
    },
    methods: {
        // 免费试用
        freeTrial() {
            this.isShowKf = true;
        },
        // 关闭客服弹窗
        closeKf() {
            this.isShowKf = false;
        },
    }
}
</script>

<style scoped lang="less">
.shop-container {
    .wind-control {
        height: 817px;
        background: rgba(244, 246, 252, 1);

        .wind-control-box {
            width: 1280px;
            margin: 0 auto;
            display: flex;

            .wind-left {
                width: 439px;

                .title {
                    width: 434px;
                    height: 65px;
                    opacity: 1;
                    /** 文本1 */
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 64px;
                    color: rgba(40, 41, 56, 1);
                    text-align: left;
                    margin-top: 116px;
                }

                .m-title {
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 36px;
                    color: rgba(0, 0, 0, 1);
                    text-align: left;
                    margin: 23px 0;
                }

                .intro {
                    .intro-item {
                        font-size: 18px;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 36px;
                        color: rgba(103, 107, 115, 1);
                        text-align: left;
                        margin-bottom: 29px;
                        display: flex;
                        align-items: center;

                        img {
                          width: 28px;
                          height: 28px;
                          margin-right: 16px;
                        }
                    }
                    .intro-item:nth-child(2) {
                        img {
                            margin-right: 8px;
                        }
                    }
                }

                .button {
                    position: relative;

                    .btn-name {
                        width: 230px;
                        height: 64px;
                        opacity: 1;
                        border-radius: 41px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        left: 80px;
                        top: 16px;
                        /** 文本1 */
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 32px;
                        color: rgba(255, 255, 255, 1);
                        cursor: pointer;
                        background: rgba(95, 64, 230, 1);
                        background: linear-gradient(137.3deg, rgba(95, 64, 230, 1) 0%, rgba(83, 51, 227, 1) 100%);
                        box-shadow: 2px 6px 18px 4px rgba(111, 81, 240, 0.35);
                    }

                    .btn-name:hover {
                        background: linear-gradient(137.3deg, rgba(95, 64, 230, 0.63) 0%, rgba(83, 51, 227, 1) 100%);
                    }
                }


            }

            .wind-right {
                img {
                    height: 511px;
                    margin-top: 123px;
                    margin-left: 235px;
                }
            }
        }
    }

    .miaohu-sass {
        height: 1568px;
        background: rgba(255, 255, 255, 1);

        .miaohu-sass-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                width: 868px;
                height: 66px;
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                text-align: left;
                margin: 73px auto 0;
            }

            .intro {
                width: 757px;
                height: 66px;
                opacity: 0.7;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin: 22px auto 0;
            }

            .content {
                display: flex;
                flex-flow: wrap;
                justify-content: space-around;

                .content-item {
                    width: 405px;
                    height: 272px;
                    background: rgba(255, 255, 255, 1);
                    margin-bottom: 34px;

                    img {
                        width: 50px;
                        height: 50px;
                        margin: 39px 0 19px 45px;
                    }

                    .content-title {
                        width: 315px;
                        height: 36px;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 36px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                        margin: 19px 0 12px 45px;
                    }

                    .content-intro {
                        width: 325px;
                        height: 56px;
                        opacity: 0.7;
                        /** 文本1 */
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                        margin-left: 45px;
                    }
                }

                //.content-item:nth-child(3n) {
                //    margin-right: 0;
                //}
            }
        }
    }

    .process {
        height: 1471px;
        background: rgba(255, 255, 255, 1);


        .process-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                width: 967px;
                height: 65px;
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin: 0 auto;
                padding-top: 116px;
            }

            .intro {
                width: 500px;
                height: 28px;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                margin: 20px auto 82px;
            }

            .content {
                display: flex;
                flex-flow: wrap;

                .content-item {
                    margin-bottom: 50px;

                    img {
                        width: 623px;
                        height: 380px;
                    }

                    .content-title {
                        width: 152px;
                        height: 56px;
                        font-size: 38px;
                        font-weight: 600;
                        line-height: 56px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                        margin-top: 24px;
                        margin-bottom: 12px;
                    }

                    .content-intro {
                        width: 623px;
                        height: 28px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                    }
                }

                .content-item:nth-of-type(odd) {
                    margin-right: 34px;
                }
            }
        }
    }

    .large-banner {
        height: 497px;
        background: url("@/assets/images/shop/bg-7.jpg") 50%;
        -webkit-background-size: 100%;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .mask {
            height: 100%;
            width: 100%;
            background: rgba(28, 30, 83, 0.5);;
            position: absolute;
            top: 0;
            left: 0;
        }

        mask:after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            background-color: rgba(28, 30, 83, 0.5);;
            opacity: 0.2;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        .title {
            width: 1096px;
            height: 73px;
            opacity: 1;
            /** 文本1 */
            font-size: 54px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 74px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            vertical-align: top;
            z-index: 1;
        }

        .content {
            width: 897px;
            height: 73px;
            opacity: 1;
            /** 文本1 */
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 28px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            vertical-align: top;
            z-index: 1;
            margin-top: 44px;
        }
    }
}
</style>