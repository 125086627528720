<template>
    <div class="footer-container">
        <div class="footer-box">
            <div class="left-box">
                <div class="logo-box">
                    <img class="logo" src="@/assets/images/logo.png" alt=""/>
                    <img class="title" src="@/assets/images/title.png" alt="">
                    <img class="qw-logo" src="@/assets/images/qw-logo.png" alt="">
                </div>
                <div class="content">
                    深圳市窗花科技有限公司专注于私域建设领域，帮助企业用户管理用户，提升营销效率，为您实现可观的营收增长
                </div>
                <div class="qrcode-box">
                    <div class="qrcode-shop-box">
                        <img class="qrcode-shop" src="@/assets/images/qrcode-shop.jpg" alt="">
                        <div>妙狐自营商城</div>
                    </div>
                    <div class="qrcode-serve-box">
                        <img class="qrcode-serve" src="@/assets/images/qrcode-serve.jpg" alt="">
                        <div>窗花科技服务号</div>
                    </div>
                </div>
            </div>
            <div class="right-box">
                <div class="title">申请试用妙狐SCRM系统</div>
                <div class="content">
                    我们可以提供长达1个月的免费试用期限，您可以扫码立即联系我们开通妙狐SCRM和妙狐SAAS商城系统
                </div>
                <div class="qrcode-box">
                    <img class="qrcode-kf" src="@/assets/images/qrcode-kf.png" alt="">
                    <span>&lt;&lt;&lt; 扫码添加客服微信</span>
                </div>
            </div>
        </div>
        <div class="filing-box">
            <div class="filing">
                <span>Copyright 2022, 深圳市窗花科技有限公司</span>
                <span>
                    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19040433号</a>
                </span>
                <span>电话：0755-61276619</span>
                <span>地址：深圳市宝安区新安街道合谷美苑地利楼3楼</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Footer"
}
</script>

<style scoped lang="less">
.footer-container {
    clear: both;
    height: 489px;
    opacity: 1;
    background: rgba(28, 30, 83, 1);


    .footer-box {
        width: 1280px;
        margin: 0 auto;
        height: 397px;
        display: flex;

        .left-box {
            margin-top: 55px;
            width: 405px;
            height: 315px;
            opacity: 1;

            .logo-box {
                display: flex;
                align-items: center;
            }

            .logo {
                width: 52px;
                height: 52px;
            }

            .title {
                width: 104px;
                height: 37px;
            }

            .qw-logo {
                width: 200px;
                height: 36px;
                margin-left: 22px;
            }

            .content {
                width: 399px;
                height: 84px;
                /** 文本1 */
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 28px;
                color: rgba(244, 246, 252, 1);
                margin-top: 15px;
            }

            .qrcode-box {
                display: flex;
                margin-top: 8px;

                .qrcode-shop-box {
                    margin-left: 29px;
                }

                .qrcode-serve-box {
                    margin-left: 67px;
                }

                .qrcode-shop-box, .qrcode-serve-box {
                    text-align: center;
                    color: rgba(244, 246, 252, 1);
                    font-size: 16px;
                    font-weight: 500;
                }

                .qrcode-shop-box div {
                    margin-top: 8px;
                }

                .qrcode-serve-box div {
                    margin-top: 8px;
                }

                img {
                    width: 120px;
                    height: 120px;
                }
            }
        }

        .right-box {
            margin-top: 55px;
            margin-left: 348px;
            width: 432px;
            height: 279px;
            opacity: 1;

            .title {
                font-family: Poppins, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
                font-size: 32px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 48px;
                color: rgba(255, 255, 255, 1);
            }

            .content {
                width: 432px;
                height: 56px;
                opacity: 1;
                /** 文本1 */
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                color: rgba(244, 246, 252, 1);
                margin-top: 21px;
            }

            .qrcode-box {
                margin-top: 34px;

                .qrcode-kf {
                    width: 120px;
                    height: 120px;
                }

                span {
                    /** 文本1 */
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 28px;
                    color: rgba(244, 246, 252, 1);
                    margin-left: 26px;
                }
            }
        }
    }

    .filing-box {
        height: 91px;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 1);
        border-top: 1px solid #373968;

        .filing {
            width: 1280px;
            margin: 0 auto;

            span:first-child {
                margin-left: 0;
            }

            span {
                margin: 0 14px;
            }

            a {
                text-decoration: none;
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}
</style>