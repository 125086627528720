var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-container"},[_c('div',{staticClass:"product-price"},[_c('div',{staticClass:"product-price-box"},[_c('div',{staticClass:"title"},[_vm._v("妙狐SCRM产品价格")]),_c('div',{staticClass:"intro"},[_vm._v(" 以下为SAAS版本标准化产品价格，如有私有化部署，定制开发等需求，请联系我们专业的技术客服沟通；互通账号、会话存档、AI云呼按需计费 ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-item"},[_vm._m(0),_c('div',{staticClass:"version"},[_vm._v("基础版")]),_c('div',{staticClass:"version-intro"},[_vm._v("适用于50人以内中小规模企业，超出10人按每个席位98元/年计费")]),_vm._m(1),_c('div',{staticClass:"btn-buy",on:{"click":_vm.freeTrial}},[_vm._v("立即购买")])]),_c('div',{staticClass:"content-item"},[_vm._m(2),_c('div',{staticClass:"version"},[_vm._v("专业版")]),_c('div',{staticClass:"version-intro"},[_vm._v("适用于50-100人以内中型企业，超出按每个席位58元/年计费")]),_vm._m(3),_c('div',{staticClass:"btn-buy active",on:{"click":_vm.freeTrial}},[_vm._v("立即购买")])]),_c('div',{staticClass:"content-item"},[_vm._m(4),_c('div',{staticClass:"version"},[_vm._v("旗舰版")]),_c('div',{staticClass:"version-intro"},[_vm._v("适用于100人以上大型企业，不限制员工席位数量")]),_vm._m(5),_c('div',{staticClass:"btn-buy",on:{"click":_vm.freeTrial}},[_vm._v("立即购买")])])])])]),_vm._m(6),_c('KfPopUp',{attrs:{"isShow":_vm.isShowKf},on:{"closeKf":_vm.closeKf}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-box"},[_c('div',{staticClass:"box-left"},[_vm._v("￥2998/年")]),_c('div',{staticClass:"box-right"},[_vm._v("含10个席位")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version-item-box"},[_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("基础客户管理功能")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("全渠道数据打通")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("营销扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("服务扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-2.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("会话扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-2.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("AI云呼")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-box"},[_c('div',{staticClass:"box-left"},[_vm._v("￥3998/年")]),_c('div',{staticClass:"box-right"},[_vm._v("含50个席位")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version-item-box"},[_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("基础客户管理功能")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("全渠道数据打通")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("营销扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("服务扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-2.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("会话扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-2.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("AI云呼")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price-box"},[_c('div',{staticClass:"box-left"},[_vm._v("￥4998/年")]),_c('div',{staticClass:"box-right"},[_vm._v("不限席位数量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"version-item-box"},[_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("基础客户管理功能")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("全渠道数据打通")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title"},[_vm._v("营销扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-1.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("服务扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-2.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("会话扩展包")])]),_c('div',{staticClass:"version-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/right-2.png"),"alt":""}}),_c('span',{staticClass:"item-title title-grey"},[_vm._v("AI云呼")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"choose-us"},[_c('div',{staticClass:"choose-us-box"},[_c('div',{staticClass:"title"},[_vm._v("为什么选择我们")]),_c('div',{staticClass:"intro"},[_vm._v("能为企业级客户提供全流程私域流量运营工具和咨询服务，帮助企业客户持续增长")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/icon-1.png"),"alt":""}}),_c('div',{staticClass:"content-item-title"},[_vm._v("技术优势")]),_c('div',{staticClass:"content-item-intro"},[_c('div',[_vm._v("多年的市场产品运营经验，紧抓用户的真是需求，快速而高效的迭代")]),_c('div',[_vm._v("技术过硬的研发团队，100+技术团队，多年的开发经验")])])]),_c('div',{staticClass:"content-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/icon-2.png"),"alt":""}}),_c('div',{staticClass:"content-item-title"},[_vm._v("产品优势")]),_c('div',{staticClass:"content-item-intro"},[_c('div',[_vm._v("5年持续投入的产品沉淀，塑造良好的产品体验，众多客户验证的优势选择")]),_c('div',[_vm._v("多平台数据API接口打通，真正做到全网客户私域运营")])])]),_c('div',{staticClass:"content-item"},[_c('img',{attrs:{"src":require("@/assets/images/price/icon-3.png"),"alt":""}}),_c('div',{staticClass:"content-item-title"},[_vm._v("服务优势")]),_c('div',{staticClass:"content-item-intro"},[_c('div',[_vm._v("7*24小时追踪指导，及时提供有效解决方案 ，完善的产品培训机制，轻易上手使用")]),_c('div',[_vm._v("单企业一对一服务响应，产品、技术、运营、客服为您提供保姆式服务")])])])])])])
}]

export { render, staticRenderFns }