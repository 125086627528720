<template>
    <div class="price-container">
        <!-- 妙狐SCRM产品价格 -->
        <div class="product-price">
            <div class="product-price-box">
                <div class="title">妙狐SCRM产品价格</div>
                <div class="intro">
                    以下为SAAS版本标准化产品价格，如有私有化部署，定制开发等需求，请联系我们专业的技术客服沟通；互通账号、会话存档、AI云呼按需计费
                </div>
                <div class="content">
                    <div class="content-item">
                        <div class="price-box">
                            <div class="box-left">￥2998/年</div>
                            <div class="box-right">含10个席位</div>
                        </div>
                        <div class="version">基础版</div>
                        <div class="version-intro">适用于50人以内中小规模企业，超出10人按每个席位98元/年计费</div>
                        <div class="version-item-box">
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">基础客户管理功能</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">全渠道数据打通</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">营销扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title title-grey">服务扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-2.png" alt="">
                                <span class="item-title title-grey">会话扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-2.png" alt="">
                                <span class="item-title title-grey">AI云呼</span>
                            </div>
                        </div>
                        <div class="btn-buy" @click="freeTrial">立即购买</div>
                    </div>
                    <div class="content-item">
                        <div class="price-box">
                            <div class="box-left">￥3998/年</div>
                            <div class="box-right">含50个席位</div>
                        </div>
                        <div class="version">专业版</div>
                        <div class="version-intro">适用于50-100人以内中型企业，超出按每个席位58元/年计费</div>
                        <div class="version-item-box">
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">基础客户管理功能</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">全渠道数据打通</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">营销扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title title-grey">服务扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-2.png" alt="">
                                <span class="item-title title-grey">会话扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-2.png" alt="">
                                <span class="item-title title-grey">AI云呼</span>
                            </div>
                        </div>
                        <div class="btn-buy active" @click="freeTrial">立即购买</div>
                    </div>
                    <div class="content-item">
                        <div class="price-box">
                            <div class="box-left">￥4998/年</div>
                            <div class="box-right">不限席位数量</div>
                        </div>
                        <div class="version">旗舰版</div>
                        <div class="version-intro">适用于100人以上大型企业，不限制员工席位数量</div>
                        <div class="version-item-box">
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">基础客户管理功能</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">全渠道数据打通</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title">营销扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-1.png" alt="">
                                <span class="item-title title-grey">服务扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-2.png" alt="">
                                <span class="item-title title-grey">会话扩展包</span>
                            </div>
                            <div class="version-item">
                                <img src="@/assets/images/price/right-2.png" alt="">
                                <span class="item-title title-grey">AI云呼</span>
                            </div>
                        </div>
                        <div class="btn-buy" @click="freeTrial">立即购买</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 为什么选择我们 -->
        <div class="choose-us">
            <div class="choose-us-box">
                <div class="title">为什么选择我们</div>
                <div class="intro">能为企业级客户提供全流程私域流量运营工具和咨询服务，帮助企业客户持续增长</div>
                <div class="content">
                    <div class="content-item">
                        <img src="@/assets/images/price/icon-1.png" alt="">
                        <div class="content-item-title">技术优势</div>
                        <div class="content-item-intro">
                            <div>多年的市场产品运营经验，紧抓用户的真是需求，快速而高效的迭代</div>
                            <div>技术过硬的研发团队，100+技术团队，多年的开发经验</div>
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/price/icon-2.png" alt="">
                        <div class="content-item-title">产品优势</div>
                        <div class="content-item-intro">
                            <div>5年持续投入的产品沉淀，塑造良好的产品体验，众多客户验证的优势选择</div>
                            <div>多平台数据API接口打通，真正做到全网客户私域运营</div>
                        </div>
                    </div>
                    <div class="content-item">
                        <img src="@/assets/images/price/icon-3.png" alt="">
                        <div class="content-item-title">服务优势</div>
                        <div class="content-item-intro">
                            <div>7*24小时追踪指导，及时提供有效解决方案 ，完善的产品培训机制，轻易上手使用</div>
                            <div>单企业一对一服务响应，产品、技术、运营、客服为您提供保姆式服务</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 客服弹框 -->
        <KfPopUp :isShow="isShowKf" @closeKf="closeKf"></KfPopUp>
    </div>
</template>

<script>
import KfPopUp from "@/components/KfPopUp";

export default {
    name: "PriceIndex",
    components: {
        KfPopUp
    },
    data() {
        return {
            isShowKf: false
        }
    },
    methods: {
        // 免费试用
        freeTrial() {
            this.isShowKf = true;
        },
        // 关闭客服弹窗
        closeKf() {
            this.isShowKf = false;
        },
    }
}
</script>

<style scoped lang="less">
.price-container {
    .product-price {
        height: 1098px;
        background: rgba(255, 255, 255, 1);

        .product-price-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                padding-top: 120px;
            }

            .intro {
                width: 562px;
                height: 56px;
                opacity: 0.7;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin: 16px auto 64px;
            }

            .content {
                display: flex;
                justify-content: space-between;

                .content-item {
                    flex-wrap: wrap;
                    width: 406px;
                    height: 745px;
                    border-radius: 12px;
                    background: rgba(244, 246, 252, 1);
                    cursor: pointer;
                    //padding: 64px 48px;

                    .price-box {
                        display: flex;
                        align-items: center;
                        margin-top: 64px;
                        margin-left: 48px;

                        .box-left {
                            width: 197px;
                            height: 56px;
                            font-size: 38px;
                            font-weight: 600;
                            line-height: 56px;
                            color: rgba(40, 41, 56, 1);
                            text-align: left;
                        }

                        .box-left.active {
                            color: rgba(255, 255, 255, 1);
                        }

                        .box-right {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 28px;
                            color: rgba(36, 5, 242, 1);
                            text-align: left;
                        }

                        .box-right.active {
                            color: rgba(252, 217, 128, 1);
                        }
                    }

                    .version {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 36px;
                        color: rgba(40, 41, 56, 1);
                        text-align: left;
                        margin-left: 48px;
                        margin-top: 20px;
                        margin-bottom: 8px;
                    }

                    .version.active {
                        color: rgba(255, 255, 255, 1);
                    }

                    .version-intro {
                        width: 281px;
                        height: 56px;
                        opacity: 0.7;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 28px;
                        color: rgba(40, 41, 56, 1);
                        margin-left: 48px;
                    }

                    .version-intro.active {
                        opacity: 0.7;
                        color: rgba(255, 255, 255, 1);
                    }

                    .version-item-box {
                        margin-top: 78px;
                        margin-left: 48px;
                        height: 268px;

                        .version-item {
                            display: flex;
                            align-items: center;
                            margin-bottom: 20px;

                            img {
                                height: 18px;
                            }

                            .item-title {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 28px;
                                color: rgba(40, 41, 56, 1);
                                text-align: left;
                                margin-left: 12px;
                            }

                            .item-title.active {
                                color: rgba(255, 255, 255, 1);
                            }

                            .title-grey {
                                opacity: 0.7;
                                color: rgba(40, 41, 56, 1);
                            }

                            .title-grey.active {
                                opacity: 0.7;
                                color: rgba(255, 255, 255, 1);
                            }
                        }

                        .version-item:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .btn-buy {
                        width: 254px;
                        height: 64px;
                        border-radius: 41px;
                        background: rgba(40, 41, 56, 1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 32px;
                        color: rgba(244, 246, 252, 1);
                        margin: 47px auto 0;
                        cursor: pointer;
                    }


                    &:hover {
                        opacity: 1;
                        background: #6246e4;
                        transition: all 0.5s;

                        .price-box {
                            .box-left {
                                color: rgba(255, 255, 255, 1);
                            }

                            .box-right {
                                color: rgba(252, 217, 128, 1);
                            }
                        }

                        .version {
                            color: rgba(255, 255, 255, 1);
                        }

                        .version-intro {
                            opacity: 0.7;
                            color: rgba(255, 255, 255, 1);
                        }

                        .version-item-box {
                            .version-item {
                                .item-title {
                                    color: rgba(255, 255, 255, 1);
                                }

                                .title-grey {
                                    opacity: 0.7;
                                    color: rgba(255, 255, 255, 1);
                                }
                            }
                        }

                        .btn-buy {
                            background: rgba(252, 217, 128, 1);
                            color: rgba(40, 41, 56, 1);
                        }
                    }
                }
            }
        }
    }

    .choose-us {
        height: 785px;
        background: rgba(244, 246, 252, 1);

        .choose-us-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                padding-top: 91px;
            }

            .intro {
                width: 562px;
                height: 56px;
                opacity: 0.7;
                font-size: 16px;
                font-weight: 500;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin: 16px auto 44px;
            }

            .content {
                display: flex;
                justify-content: space-around;

                .content-item {
                    width: 405px;
                    height: 316px;
                    opacity: 1;
                    background: rgba(254, 251, 242, 1);

                    img {
                        width: 32px;
                        height: 32px;
                        margin: 50px 0 0 48px;
                    }

                    .content-item-title {
                        font-size: 24px;
                        font-weight: 500;
                        color: rgba(40, 41, 56, 1);
                        margin-top: 26px;
                        margin-left: 48px;
                        margin-bottom: 12px;
                    }

                    .content-item-intro {
                        width: 325px;
                        height: 56px;
                        opacity: 0.7;
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(40, 41, 56, 1);
                        margin-left: 48px;

                        div {
                            margin-bottom: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>