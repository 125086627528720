<template>
    <div class="index-container">
        <!-- banner -->
        <div class="lg-banner">
            <img class="banner-l" src="@/assets/images/home/banner-bg-l.png" alt="">
            <el-carousel height="661px" indicator-position="none" arrow="never" :interval="5000" ref="carousel"
                         @change="changeCarousel">
                <el-carousel-item>
                    <div class="banner">
                        <div class="banner-box">
                            <div class="banner-left">
                                <div class="title">数字化用户增长和管理平台</div>
                                <div class="intro">搭建从营销获客-销售管理-分享裂变的私域增长闭环系统</div>
                                <div class="btn" @click="freeTrial">立即咨询</div>
                            </div>
                            <div class="banner-right">
                                <img class="banner-1" src="@/assets/images/home/banner-bg-1.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="banner">
                        <div class="banner-box">
                            <div class="banner-left">
                                <div class="title">全渠道私域增长管理工具</div>
                                <div class="intro">专注于服务教育培训、电商品牌、主播达人、线下零售、餐饮服务等行业，深得客户信赖
                                </div>
                                <div class="btn" @click="freeTrial">免费试用</div>
                            </div>
                            <div class="banner-right">
                                <img class="banner-2" src="@/assets/images/home/banner-bg-2.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="banner">
                        <div class="banner-box">
                            <div class="banner-left">
                                <div class="title">数字化用户增长和管理平台</div>
                                <div class="intro">搭建从营销获客-销售管理-分享裂变的私域增长闭环系统</div>
                                <div class="btn" @click="freeTrial">立即咨询</div>
                            </div>
                            <div class="banner-right">
                                <img class="banner-1" src="@/assets/images/home/banner-bg-1.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="banner">
                        <div class="banner-box">
                            <div class="banner-left">
                                <div class="title">全渠道私域增长管理工具</div>
                                <div class="intro">专注于服务教育培训、电商品牌、主播达人、线下零售、餐饮服务等行业，深得客户信赖
                                </div>
                                <div class="btn" @click="freeTrial">免费试用</div>
                            </div>
                            <div class="banner-right">
                                <img class="banner-2" src="@/assets/images/home/banner-bg-2.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <ul class="el-carousel__indicators el-carousel__indicators--horizontal">
                <li :class="index === indexActive ? 'is-active' : '' "
                    class="el-carousel__indicator el-carousel__indicator--horizontal" @click="toggleBanner(index)"
                    v-for="(item, index) in 2" :key="item">
                    <button class="el-carousel__button"></button>
                </li>
            </ul>
        </div>

        <!-- 妙狐SCRM -->
        <div class="scrm">
            <div class="scrm-box">
                <div class="title">妙狐SCRM，基于企业微信的私域运营管理工具</div>
                <div class="intro">通过企业微信深度链接客户，实现客户全生命周期管理，帮助企业高效建设自有的私域生态</div>
                <div class="item-box">
                    <div class="item-title">公域流量</div>
                    <div class="piece-box">
                        <div class="big-title">全渠道拓客</div>
                        <div class="piece">
                            <div class="piece-title">广告公域</div>
                            <div class="piece-content piece-single">
                                <div class="content-piece">百度/信息流/朋友圈</div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">内容公域</div>
                            <div class="piece-content piece-single">
                                <div class="content-piece">自媒体/直播/短视频</div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">微信公域</div>
                            <div class="piece-content piece-single">
                                <div class="content-piece">看一看/搜一搜/短视频</div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">线下公域</div>
                            <div class="piece-content piece-single">
                                <div class="content-piece">门店/扫码/广告</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-line">
                        <div class="item-line-title">私域增长闭环系统</div>
                        <div class="triangle"></div>
                    </div>
                    <div class="piece-box">
                        <div class="big-title">精准化营销</div>
                        <div class="piece">
                            <div class="piece-title">全域引流</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">自动发红包</div>
                                    <div class="content-piece">自动拉群</div>
                                    <div class="content-piece">批量加好友</div>
                                    <div class="content-piece">引流链接</div>
                                    <div class="content-piece">多群引流</div>
                                </div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">营销中心</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">群活动裂变</div>
                                    <div class="content-piece">多维裂变</div>
                                    <div class="content-piece">红包福利</div>
                                    <div class="content-piece">群打卡</div>
                                    <div class="content-piece">短信运营</div>
                                    <div class="content-piece">智能加粉</div>
                                </div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">营销管理</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">话术素材库</div>
                                    <div class="content-piece">互动雷达</div>
                                    <div class="content-piece">客户SOP</div>
                                    <div class="content-piece">群SOP</div>
                                    <div class="content-piece">自动打标签</div>
                                    <div class="content-piece">运营计划</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-line"></div>
                    <div class="piece-box">
                        <div class="big-title">销售转化</div>
                        <div class="piece">
                            <div class="piece-title">分销商城</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">可视化DIY</div>
                                    <div class="content-piece">多端销售</div>
                                    <div class="content-piece">限时秒杀</div>
                                    <div class="content-piece">拼团团购</div>
                                    <div class="content-piece">分销裂变</div>
                                    <div class="content-piece">积分运营</div>
                                </div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">全网销售</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">淘宝订单</div>
                                    <div class="content-piece">京东订单</div>
                                    <div class="content-piece">快手订单</div>
                                    <div class="content-piece">微店订单</div>
                                    <div class="content-piece">小鹅通</div>
                                    <div class="content-piece">有赞订单</div>
                                </div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">销售管理</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">线索公海</div>
                                    <div class="content-piece">线索客户</div>
                                    <div class="content-piece">商机管理</div>
                                    <div class="content-piece">订单管理</div>
                                    <div class="content-piece">回款管理</div>
                                    <div class="content-piece">发票管理</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-line"></div>
                    <div class="piece-box">
                        <div class="big-title">数字化管理</div>
                        <div class="piece">
                            <div class="piece-title">运营管理中心</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">客户统计</div>
                                    <div class="content-piece">成员统计</div>
                                    <div class="content-piece">客群统计</div>
                                    <div class="content-piece">绩效管理</div>
                                    <div class="content-piece">群发记录</div>
                                    <div class="content-piece">绩效看板</div>
                                </div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">服务中心</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">微信客服</div>
                                    <div class="content-piece">工单系统</div>
                                    <div class="content-piece">AI云呼</div>
                                    <div class="content-piece">网页客服</div>
                                    <div class="content-piece">企业商学院</div>
                                </div>
                            </div>
                        </div>
                        <div class="piece">
                            <div class="piece-title">企业风控</div>
                            <div class="piece-content piece-many">
                                <div class="piece-many-box">
                                    <div class="content-piece">消息存档</div>
                                    <div class="content-piece">违规提醒</div>
                                    <div class="content-piece">删人提醒</div>
                                    <div class="content-piece">词频分析</div>
                                    <div class="content-piece">朋友圈质检</div>
                                    <div class="content-piece">群聊质检</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-title">私域流量池</div>
                </div>
            </div>
        </div>

        <!-- 快速构建企业私域池 -->
        <div class="customer-operation">
            <div class="customer-operation-box">
                <div class="customer-left">
                    <div>
                        <img src="@/assets/images/home/bg-1.png" alt="">
                    </div>
                </div>
                <div class="customer-right">
                    <div class="title">快速构建企业私域池</div>
                    <div class="m-title">全渠道线索汇集到客户池，统一管理</div>
                    <div class="intro">
                        <div>智能加粉：多渠道智能加粉，公域导私域</div>
                        <div>活码加粉：门店活码、锁客码、千人千面码实现自动分流，高效涨粉</div>
                        <div>裂变加粉：多维裂变、群活动裂变、红包裂变迅速扩展您的私域粉丝</div>
                    </div>
                    <div class="button">
                        <div class="btn-name" @click="freeTrial">免费试用</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 客户管理互动转化 -->
        <div class="wind-control">
            <div class="wind-control-box">
                <div class="wind-left">
                    <div class="title">客户管理互动转化</div>
                    <div class="m-title">内容传递产品价值，行为洞察客户需求，任务 SOP 指导销售动作</div>
                    <div class="intro">
                        <div>营销计划：群SOP、个人SOP、活动管理、群日历</div>
                        <div>
                            企业话术库：企业在后台添加快捷回复话术，可以在员工聊天界面中展示，员工可以直接使用，提升沟通效率
                        </div>
                        <div>互动雷达：为发送给用户的链接和文件添加追踪功能，方便员工后期持续跟进</div>
                    </div>
                    <div class="button">
                        <div class="btn-name" @click="freeTrial">免费试用</div>
                    </div>
                </div>
                <div class="wind-right">
                    <div>
                        <img src="@/assets/images/home/bg-2.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 客户运营管理 -->
        <div class="customer-operation">
            <div class="customer-operation-box">
                <div class="customer-left">
                    <div>
                        <img src="@/assets/images/home/bg-3.png" alt="">
                    </div>
                </div>
                <div class="customer-right">
                    <div class="title">客户运营管理</div>
                    <div class="m-title">沉淀客户数据资产，高效管理每个客户</div>
                    <div class="intro">
                        <div>CRM 组件：客户画像、商机、订单从、财务帮您高效管理每个客户</div>
                        <div>智能标签：根据客户行为自动沉淀标签，识别客户画像</div>
                        <div>裂变加粉：多维裂变、群活动裂变、红包裂变迅速扩展您的私域粉丝</div>
                    </div>
                    <div class="button">
                        <div class="btn-name" @click="freeTrial">免费试用</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 企业风控管理 -->
        <div class="wind-control">
            <div class="wind-control-box">
                <div class="wind-left">
                    <div class="title">企业风控管理</div>
                    <div class="m-title">数字化全流程企业管理</div>
                    <div class="intro">
                        <div>消息存档：对员工与客户之前的聊天记录进行永久保存，且支持内容搜索，一旦发生争议可查看会话记录
                        </div>
                        <div>
                            违规提醒：监测员工和客户之间的聊天，后台设置某些关键词，群聊或私聊里有提到包含的关键词后台就会有违规记录，方便企业管理人员查看
                        </div>
                        <div>
                            群聊质检：企业在后台添加质检规则和质检群聊，触发规则后可以发送提醒通知至员工，提升企业服务质量
                        </div>
                    </div>
                    <div class="button">
                        <div class="btn-name" @click="freeTrial">免费试用</div>
                    </div>
                </div>
                <div class="wind-right">
                    <div>
                        <img src="@/assets/images/home/bg-4.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 微信生态下使用私域流量运营工具遇到的问题 -->
        <div class="question">
            <div class="question-box">
                <div class="title">微信生态下使用私域流量运营工具遇到的问题</div>
                <div class="tip">您是否面临以下营销问题，使用妙狐SCRM系统获得完整的解决方案</div>
                <div class="item-box">
                    <div class="sup">
                        <div class="item">
                            <img src="@/assets/images/home/q1.png" alt="">
                            <div class="item-title">营销获客成本高</div>
                            <div class="item-intro">获取客户越来越难,营销成本广告成本越来越高，传统CRM只能处理存量客户
                            </div>
                        </div>
                        <div class="item">
                            <img src="@/assets/images/home/q2.png" alt="">
                            <div class="item-title">电话及短信触达率低</div>
                            <div class="item-intro">工信部控制愈发严格，容易封号，标记骚扰，接通率低，营销短信极易被屏蔽
                            </div>
                        </div>
                        <div class="item">
                            <img src="@/assets/images/home/q3.png" alt="">
                            <div class="item-title">官方封号风险</div>
                            <div class="item-intro">用个人微信很难监管，用第三方插件容易被封号，客户资源容易被带走</div>
                        </div>
                        <div class="item">
                            <img src="@/assets/images/home/q4.png" alt="">
                            <div class="item-title">好友管理混乱</div>
                            <div class="item-intro">好友数太多，多个个微号运营以致管理混乱。企微SCRM助力管理客户</div>
                        </div>
                    </div>
                    <div class="down">
                        <div class="item">
                            <img src="@/assets/images/home/q5.png" alt="">
                            <div class="item-title">销售效率低 用工成本高</div>
                            <div class="item-intro">通员工联系客户效率低，靠人海战术提高销量，增加了销售成本和人力成本
                            </div>
                        </div>
                        <div class="item">
                            <img src="@/assets/images/home/q6.png" alt="">
                            <div class="item-title">客户容易被员工带走</div>
                            <div class="item-intro">用个人微信做私域流量，一旦员工离职客户也被员工带走，对企业损失太大
                            </div>
                        </div>
                        <div class="item">
                            <img src="@/assets/images/home/q7.png" alt="">
                            <div class="item-title">精细化管理难</div>
                            <div class="item-intro">对好友无法统一标签化，难以个性化精准营销。企微SCRM锁定个性化需求</div>
                        </div>
                        <div class="item">
                            <img src="@/assets/images/home/q8.png" alt="">
                            <div class="item-title">销售转化繁琐</div>
                            <div class="item-intro">难以区分客户跟进状态，后续销售转化麻烦。企微SCRM多触点触达用户</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 赋能行业，实践案例分享 -->
        <div class="empowerment">
            <div class="empowerment-box">
                <div class="title">赋能行业，实践案例分享</div>
                <div class="tip">零售、金融、电商、餐饮、美容、教育多行业成功案例实践分享</div>
                <el-carousel height="260px" indicator-position="none" arrow="never" :interval="5000" ref="empowerment" @change="changeEmpowerment">
                    <el-carousel-item>
                        <div class="content-box">
                            <div class="content">
                                "妙狐的SCRM系统帮助我们在极短的时间内覆盖了2000个县市，快速的抢占市场；高效管理超过数万团长的日常/营销和绩效管理"
                            </div>
                            <div class="figure-box">
                                <div>
                                    <img class="avatar" src="@/assets/images/home/avatar.png" alt="">
                                </div>
                                <div>
                                    <div class="name">易总</div>
                                    <div class="intro">某头部新零售企业</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="content-box">
                            <div class="content">
                                "引入妙狐SCRM系统之后，全面提升了我们的客户管理效率，学员的课程购买转化率、售后满意度都有显著提升，学生家长反馈很好"
                            </div>
                            <div class="figure-box">
                                <div>
                                    <img class="avatar" src="@/assets/images/home/avatar.jpg" alt="">
                                </div>
                                <div>
                                    <div class="name">蔡总监</div>
                                    <div class="intro">某教培机构负责人</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="content-box">
                            <div class="content">
                                "妙狐的SCRM系统帮助我们在极短的时间内覆盖了2000个县市，快速的抢占市场；高效管理超过数万团长的日常/营销和绩效管理"
                            </div>
                            <div class="figure-box">
                                <div>
                                    <img class="avatar" src="@/assets/images/home/avatar.png" alt="">
                                </div>
                                <div>
                                    <div class="name">易总</div>
                                    <div class="intro">某头部新零售企业</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="content-box">
                            <div class="content">
                                "引入妙狐SCRM系统之后，全面提升了我们的客户管理效率，学员的课程购买转化率、售后满意度都有显著提升，学生家长反馈很好"
                            </div>
                            <div class="figure-box">
                                <div>
                                    <img class="avatar" src="@/assets/images/home/avatar.jpg" alt="">
                                </div>
                                <div>
                                    <div class="name">蔡总监</div>
                                    <div class="intro">某教培机构负责人</div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
<!--                <ul class="el-carousel__indicators el-carousel__indicators&#45;&#45;horizontal">-->
<!--                    <li :class="index === indexActive ? 'is-active' : '' "-->
<!--                        class="el-carousel__indicator el-carousel__indicator&#45;&#45;horizontal" @click="toggleBanner(index)"-->
<!--                        v-for="(item, index) in 2" :key="item">-->
<!--                        <button class="el-carousel__button"></button>-->
<!--                    </li>-->
<!--                </ul>-->





                <div class="pagination">
                    <img class="left-icon" @click="toggleEmpowerment(0)" src="@/assets/images/home/page-left.png"
                         alt="">
                    <img class="right-icon" @click="toggleEmpowerment(1)" src="@/assets/images/home/page-right.png"
                         alt="">
                </div>
            </div>
        </div>

        <!-- 妙狐SCRM系统，开启您的私域建设之旅 -->
        <div class="large-banner">
            <div class="mask"></div>
            <div class="title">妙狐SCRM系统，开启您的私域建设之旅</div>
            <div class="content">
                承载从获客到成单整个营销闭环，打通与主流电商平台的对接，数据安全可靠；助您搭建私域流量池，个性化内容触达，持续孵化，助力您的客户增长和客户运营
            </div>
        </div>

        <!-- 客服弹框 -->
        <KfPopUp :isShow="isShowKf" @closeKf="closeKf"></KfPopUp>
    </div>
</template>

<script>
import KfPopUp from "@/components/KfPopUp";

export default {
    name: "HomeIndex",
    components: {
        KfPopUp
    },
    data() {
        return {
            isShowKf: false,
            indexActive: 0,
            indexActive2: 0,
            timer: null,
        };
    },
    methods: {
        // 免费试用
        freeTrial() {
            this.isShowKf = true;
        },
        // 关闭客服弹窗
        closeKf() {
            this.isShowKf = false;
        },
        // banner切换
        toggleBanner(index) {
            this.$refs.carousel.setActiveItem(index === 0 ? 0 : 1);
        },
        toggleEmpowerment(type) {
            this.$refs.empowerment.setActiveItem(type === 0 ? (this.indexActive2 === 0 ? 3 : this.indexActive2 - 1) : (this.indexActive2 === 3 ? 0 : this.indexActive2 + 1));
        },
        changeCarousel(a) {
            a === 2 ? this.indexActive = 0 : a === 3 ? this.indexActive = 1 : this.indexActive = a
        },
        changeEmpowerment(a) {
            this.indexActive2 = a;
        }
    },
}
</script>

<style lang="less">
.index-container {
    .el-carousel__indicator--horizontal {
        padding: 34px 6px;

        .el-carousel__button {
            width: 36px;
            height: 10px;
            opacity: 1;
            border-radius: 20px;
            background: rgba(165, 155, 235, 1);
        }
    }

    .el-carousel__indicator--horizontal.is-active {
        .el-carousel__button {
            background: rgba(255, 255, 255, 1);
        }
    }
}
</style>

<style scoped lang="less">
.index-container {
    .lg-banner {
        height: 661px;
        position: relative;
        background: rgba(98, 70, 228, 1);

        .banner-l {
            width: 118px;
            height: 661px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 3;
        }
    }

    .banner {
        background: rgba(98, 70, 228, 1);
        height: 661px;
        position: relative;

        .banner-box {
            width: 1280px;
            margin: 0 auto;
            height: 452px;
            display: flex;
            padding-top: 119px;

            .title {
                width: 511px;
                height: 148px;
                opacity: 1;
                /** 文本1 */
                font-size: 54px;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 74px;
                color: rgba(255, 255, 255, 1);
                text-align: left;
            }

            .intro {
                width: 531px;
                height: 72px;
                /** 文本1 */
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 36px;
                color: rgba(208, 199, 247);
                text-align: left;
                margin-top: 66px;
            }

            .btn {
                width: 230px;
                height: 64px;
                opacity: 1;
                border-radius: 41px;
                background: rgba(252, 217, 128, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 32px;
                color: rgba(27, 28, 43, 1);
                margin-top: 64px;
                cursor: pointer;
            }

            .banner-right {
                .banner-1 {
                    margin-left: 45px;
                    width: 602.67px;
                    height: 452px;
                    opacity: 1;
                }

                .banner-2 {
                    margin-left: 45px;
                    margin-top: 26px;
                    width: 638px;
                    height: 362px;
                    opacity: 1;
                }
            }
        }
    }

    .scrm {
        height: 1056px;
        background: rgba(244, 246, 252, 1);

        .scrm-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: 600;
                color: rgba(40, 41, 56, 1);
                padding-top: 98px;
                text-align: center;
            }

            .intro {
                width: 500px;
                height: 56px;
                opacity: 0.7;
                /** 文本1 */
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                text-align: center;
                margin: 0 auto;
                margin-top: 16px;
                margin-bottom: 31px;
            }

            .item-box {
                display: flex;
                justify-content: center;
                height: 664px;

                .item-title {
                    width: 48px;
                    height: 610px;
                    opacity: 1;
                    border-radius: 4px;
                    background: linear-gradient(180deg, rgba(103, 77, 219, 0.7) 0%, rgba(69, 34, 227, 1) 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    font-weight: 700;
                    color: rgba(255, 255, 255, 1);
                    writing-mode: vertical-lr;
                    letter-spacing: 10px;
                    margin-top: 50px;
                }

                .item-title:first-child {
                    margin-right: 23px;
                }

                .item-title:last-child {
                    margin-left: 23px;
                }

                .item-line {
                    width: 0;
                    height: 612px;
                    opacity: 1;
                    background: #cccccc;
                    border-right: 1px dashed #5233de;
                    margin-top: 50px;
                    position: relative;

                    .item-line-title {
                        width: 43px;
                        height: 356px;
                        opacity: 1;
                        border-radius: 20px;
                        background: rgba(244, 246, 252, 1);
                        border: 1px solid rgba(82, 51, 222, 1);
                        font-size: 18px;
                        font-weight: 700;
                        color: rgba(82, 51, 222, 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        writing-mode: vertical-lr;
                        letter-spacing: 10px;
                        position: absolute;
                        top: 130px;
                        left: -22px;
                    }

                    .item-line-title {
                        width: 43px;
                        height: 356px;
                        opacity: 1;
                        border-radius: 20px;
                        background: rgba(244, 246, 252, 1);
                        border: 1px solid rgba(82, 51, 222, 1);
                    }

                    .triangle {
                        height: 0;
                        width: 0;
                        border-color: transparent transparent transparent rgba(82, 51, 222, 1);
                        border-style: solid;
                        border-width: 16px 0 16px 20px;
                        position: absolute;
                        top: 288px;
                        left: 22px;
                    }

                    .triangle:after {
                        content: '';
                        border-style: solid;
                        border-width: 15px 0 15px 19px;
                        border-color: transparent transparent transparent rgba(244, 246, 252, 1);
                        position: absolute;
                        top: -15px;
                        left: -20px;
                    }
                }

                .item-line:nth-child(3) {
                    margin-left: 50px;
                    margin-right: 50px;
                }

                .item-line:nth-child(5), .item-line:nth-child(7) {
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }

            .piece-box {
                text-align: center;

                .big-title {
                    font-size: 24px;
                    font-weight: 700;
                    color: rgba(82, 51, 222, 1);
                    text-align: center;
                    margin-bottom: 19px;
                }

                .piece {

                    width: 200px;
                    opacity: 1;
                    border-radius: 8px;
                    background: rgba(255, 255, 255, 1);
                    border: 1px solid rgba(36.00000000000009, 5.000000000000013, 242, 1);
                    margin-bottom: 19px;

                    .piece-title {
                        height: 50px;
                        opacity: 1;
                        border-radius: 8px;
                        background: rgba(166, 151, 232, 0.11);
                        border-bottom: 1px solid rgba(36.00000000000009, 5.000000000000013, 242, 1);
                        font-size: 18px;
                        font-weight: 700;
                        color: rgba(0, 0, 0, 1);
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .piece-content {
                        .content-piece {
                            font-weight: 400;
                            color: rgba(0, 0, 0, 1);
                        }
                    }

                    .piece-single {
                        height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .piece-many {
                        height: 138px;
                        text-align: left;
                        display: flex;
                        align-items: center;

                        .piece-many-box {
                            height: 81px;
                            display: flex;
                            align-items: center;
                            flex-flow: wrap;
                            justify-content: space-between;
                            padding-left: 20px;
                        }

                        .content-piece {
                            width: 50%;
                        }
                    }
                }
            }

            .item-line {
                .item-line-title {
                    writing-mode: vertical-lr;
                }
            }
        }
    }

    .customer-operation {
        height: 817px;
        background: rgba(255, 255, 255, 1);

        .customer-operation-box {
            width: 1280px;
            margin: 0 auto;
            display: flex;

            .customer-left {
                img {
                    width: 780px;
                    height: 512px;
                    margin-top: 188px;
                    margin-right: 61px;
                    //box-shadow: 0 0 20px 2px rgba(218, 213, 237, 1);
                }
            }

            .customer-right {
                width: 439px;

                .title {
                    width: 434px;
                    height: 65px;
                    opacity: 1;
                    /** 文本1 */
                    font-size: 48px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 64px;
                    color: rgba(40, 41, 56, 1);
                    text-align: left;
                    vertical-align: top;
                    margin-top: 116px;
                }

                .m-title {
                    width: 398px;
                    height: 108px;
                    /** 文本1 */
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 36px;
                    color: rgba(0, 0, 0, 1);
                    text-align: left;
                    margin-top: 23px;
                }

                .intro {
                    div {
                        width: 398px;
                        /** 文本1 */
                        font-size: 18px;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 36px;
                        color: rgba(103, 107, 115, 1);
                        text-align: left;
                        margin-bottom: 17px;
                    }
                }

                .button {
                    position: relative;

                    .btn-name {
                        width: 230px;
                        height: 64px;
                        opacity: 1;
                        border-radius: 41px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        left: 80px;
                        top: 16px;
                        /** 文本1 */
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 32px;
                        color: rgba(255, 255, 255, 1);
                        cursor: pointer;
                        background: rgba(95, 64, 230, 1);
                        background: linear-gradient(137.3deg, rgba(95, 64, 230, 1) 0%, rgba(83, 51, 227, 1) 100%);
                        box-shadow: 2px 6px 18px 4px rgba(111, 81, 240, 0.35);
                    }

                    .btn-name:hover {
                        background: linear-gradient(137.3deg, rgba(95, 64, 230, 0.63) 0%, rgba(83, 51, 227, 1) 100%);
                    }
                }
            }
        }
    }

    .wind-control {
        height: 817px;
        background: rgba(244, 246, 252, 1);

        .wind-control-box {
            width: 1280px;
            margin: 0 auto;
            display: flex;

            .wind-left {
                width: 439px;

                .title {
                    width: 434px;
                    height: 65px;
                    opacity: 1;
                    /** 文本1 */
                    font-size: 48px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 64px;
                    color: rgba(40, 41, 56, 1);
                    text-align: left;
                    vertical-align: top;
                    margin-top: 116px;
                }

                .m-title {
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 36px;
                    color: rgba(0, 0, 0, 1);
                    text-align: left;
                    margin: 23px 0;
                }

                .intro {
                    div {
                        width: 398px;
                        font-size: 18px;
                        font-weight: 400;
                        letter-spacing: 0;
                        line-height: 36px;
                        color: rgba(103, 107, 115, 1);
                        text-align: left;
                        margin-bottom: 29px;
                    }
                }

                .button {
                    position: relative;

                    .btn-name {
                        width: 230px;
                        height: 64px;
                        opacity: 1;
                        border-radius: 41px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        left: 80px;
                        top: 16px;
                        /** 文本1 */
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 32px;
                        color: rgba(255, 255, 255, 1);
                        cursor: pointer;
                        background: rgba(95, 64, 230, 1);
                        background: linear-gradient(137.3deg, rgba(95, 64, 230, 1) 0%, rgba(83, 51, 227, 1) 100%);
                        box-shadow: 2px 6px 18px 4px rgba(111, 81, 240, 0.35);
                    }

                    .btn-name:hover {
                        background: linear-gradient(137.3deg, rgba(95, 64, 230, 0.63) 0%, rgba(83, 51, 227, 1) 100%);
                    }
                }
            }

            .wind-right {
                img {
                    width: 780px;
                    height: 512px;
                    margin-top: 188px;
                    margin-left: 61px;
                }
            }
        }
    }

    .question {
        height: 852px;
        background: rgba(255, 255, 255, 1);

        .question-box {
            width: 1280px;
            margin: 0 auto;

            .title {
                width: 967px;
                height: 65px;
                opacity: 1;
                /** 文本1 */
                font-size: 48px;
                font-weight: 600;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                text-align: left;
                margin: 0 auto;
                margin-top: 117px;
            }

            .tip {
                width: 500px;
                height: 28px;
                /** 文本1 */
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                margin: 0 auto;
                margin-top: 21px;
            }

            .item-box {
                margin-top: 84px;

                .item {
                    width: 227px;
                    height: 186px;
                    opacity: 1;
                    text-align: center;
                    margin: 0 48px;

                    img {
                        width: 98px;
                        height: 98px;
                    }

                    .item-title {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 1);
                        text-align: center;
                        margin-top: 13px;
                        margin-bottom: 8px;
                    }

                    .item-intro {
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(103, 107, 115, 1);
                        text-align: center;
                    }
                }

                .sup {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .down {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 61px;
                }
            }
        }
    }

    .empowerment {
        height: 793px;
        background: rgba(244, 246, 252, 1);

        .empowerment-box {
            width: 1280px;
            margin: 0 auto;
            position: relative;

            .title {
                width: 967px;
                height: 65px;
                opacity: 1;
                /** 文本1 */
                font-size: 48px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 64px;
                color: rgba(40, 41, 56, 1);
                margin: 0 auto;
                text-align: center;
                padding-top: 116px;
            }

            .tip {
                width: 500px;
                height: 28px;
                opacity: 0.7;
                /** 文本1 */
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 28px;
                color: rgba(40, 41, 56, 1);
                margin: 0 auto;
                text-align: center;
                vertical-align: top;
                margin-top: 20px;
            }

            .content {
                left: 190px;
                top: 6339px;
                width: 1236px;
                height: 96px;
                opacity: 1;
                /** 文本1 */
                font-size: 32px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 48px;
                color: rgba(40, 41, 56, 1);
                text-align: left;
                vertical-align: top;
                margin-top: 74px;
            }

            .figure-box {
                display: flex;
                align-items: center;
                margin-top: 23px;

                .avatar {
                    width: 52px;
                    height: 52px;
                    border-radius: 100%;
                    margin-right: 14px;
                }

                .name {
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 32px;
                    color: rgba(40, 41, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }

                .intro {
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 28px;
                    color: rgba(40, 41, 56, 1);
                    text-align: left;
                    vertical-align: top;
                }
            }

            .pagination {
                display: flex;
                justify-content: right;
                margin-top: 135px;

                .left-icon {
                    width: 48px;
                    height: 48px;
                    background: rgba(255, 255, 255, 1);
                    border-radius: 100px;
                    cursor: pointer;
                }

                .right-icon {
                    width: 48px;
                    height: 48px;
                    background: rgba(82, 57, 250, 1);
                    border-radius: 100px;
                    margin-left: 11px;
                    cursor: pointer;
                }
            }
        }

        //padding: 116px 190px 0 190px;
    }

    .large-banner {
        height: 497px;
        background-image: url("@/assets/images/home/lg-bg.jpg");
        -webkit-background-size: 100%;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .mask {
            height: 100%;
            width: 100%;
            background: rgba(28, 30, 83, 0.5);;
            position: absolute;
            top: 0;
            left: 0;
        }

        mask:after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            background-color: rgba(28, 30, 83, 0.5);;
            opacity: 0.2;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        .title {
            width: 1096px;
            height: 73px;
            opacity: 1;
            /** 文本1 */
            font-size: 54px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 74px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            vertical-align: top;
            z-index: 1;
        }

        .content {
            width: 897px;
            height: 73px;
            opacity: 1;
            /** 文本1 */
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 28px;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            vertical-align: top;
            z-index: 1;
            margin-top: 44px;
        }
    }
}
</style>